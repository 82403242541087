import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function KVText({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/kv-text.svg" alt="kViewer service name" />;
}

export default KVText;

import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function KBText({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/kb-text.svg" alt="kBackup service name" />;
}

export default KBText;

import LogoWithDialog from '@/containers/LogoWithDialog';
import FBLogo from '@/components/logos/FBLogo';
import FBText from '@/components/logos/FBText';
import KVLogo from '@/components/logos/KVLogo';
import KVText from '@/components/logos/KVText';
import DCLogo from '@/components/logos/DCLogo';
import DCText from '@/components/logos/DCText';
import HorizontalLogo from '@/components/logos/HorizontalLogo';
import KMLogo from '@/components/logos/KMLogo';
import KMText from '@/components/logos/KMText';
import PCLogo from '@/components/logos/PCLogo';
import PCText from '@/components/logos/PCText';
import KBLogo from '@/components/logos/KBLogo';
import KBText from '@/components/logos/KBText';

type LayoutWithLogoProps = {
  children: React.ReactNode;
};

function LayoutWithLogo({ children }: LayoutWithLogoProps) {
  return (
    <div className="max-w-screen-sm space-y-10 pt-12 sm:flex sm:items-center sm:gap-x-8 sm:space-y-0 sm:rounded-sm sm:px-8 sm:py-16 sm:shadow-login">
      {/* ロゴグループ */}
      <div className="mx-auto w-[13.5rem] shrink-0 space-y-2">
        {/* Toyokumo kintoneAppロゴ */}
        <LogoWithDialog />
        {/* 各製品ロゴ */}
        <div className="grid grid-cols-2 gap-2">
          <a href="https://fb.kintoneapp.com/" target="_blank" rel="noreferrer">
            <HorizontalLogo
              logo={<FBLogo width={20} height={20} />}
              text={<FBText width={78} height={16} />}
              space="[3px]"
            />
          </a>
          <a href="https://kv.kintoneapp.com/" target="_blank" rel="noreferrer">
            <HorizontalLogo
              logo={<KVLogo width={20} height={20} />}
              text={<KVText width={53.8} height={16} />}
              space="[3px]"
            />
          </a>
          <a href="https://km.kintoneapp.com/" target="_blank" rel="noreferrer">
            <HorizontalLogo
              logo={<KMLogo width={20} height={20} />}
              text={<KMText width={50} height={16} />}
              space="[3px]"
            />
          </a>
          <a href="https://pc.kintoneapp.com/" target="_blank" rel="noreferrer">
            <HorizontalLogo
              logo={<PCLogo width={20} height={20} />}
              text={<PCText width={83} height={16} />}
              space="[3px]"
            />
          </a>
          <a href="https://dc.kintoneapp.com/" target="_blank" rel="noreferrer">
            <HorizontalLogo
              logo={<DCLogo width={20} height={20} />}
              text={<DCText width={79} height={16} />}
              space="[3px]"
            />
          </a>
          <a href="https://kb.kintoneapp.com/" target="_blank" rel="noreferrer">
            <HorizontalLogo
              logo={<KBLogo width={20} height={20} />}
              text={<KBText width={56} height={16} />}
              space="[3px]"
            />
          </a>
        </div>
      </div>
      {/* コンテンツ */}
      <div className="px-4 sm:min-w-[20.5rem] sm:px-0">{children}</div>
    </div>
  );
}

export default LayoutWithLogo;

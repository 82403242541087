import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function KBLogo({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/kb-logo.svg" alt="KBackup logo" />;
}

export default KBLogo;

import { useState } from 'react';
import WhatIsKintoneAppAuthDialog from '@/components/WhatIsKintoneAppAuthDialog';
import VerticalLogoAndServiceName from '@/components/VerticalLogoAndServiceName';
import { useIsJa } from '@/hooks/i18n';
import { useTrackEvent } from '@/hooks/amplitude';
import { EN_CLICK_OPEN_WHAT_IS_KINTONEAPP_AUTH } from '@/config/amplitude';

function LogoWithDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const onOpen = () => {
    trackEvent(EN_CLICK_OPEN_WHAT_IS_KINTONEAPP_AUTH);
    setIsOpen(true);
  };
  const onClose = () => setIsOpen(false);
  const isJa = useIsJa();

  return (
    <>
      {isJa && <WhatIsKintoneAppAuthDialog isOpen={isOpen} onClose={onClose} />}
      <VerticalLogoAndServiceName onClick={onOpen} />
    </>
  );
}

export default LogoWithDialog;

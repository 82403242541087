type ScreenCenter = {
  children: React.ReactNode;
};

function ScreenCenter({ children }: ScreenCenter) {
  return (
    <div className="sm:flex sm:h-screen sm:min-h-screen">
      <main className="flex flex-1 flex-col items-center justify-center">{children}</main>
    </div>
  );
}

export default ScreenCenter;

import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function DCLogo({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/dc-logo.svg" alt="DataCollect logo" />;
}

export default DCLogo;

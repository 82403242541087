import Image from 'next/image';
import BasicLink from '@/components/BasicLink';
import Modal from '@/components/Modal';
import Heading3 from '@/components/typography/Heading3';
import SubText from '@/components/typography/SubText';
import Text from '@/components/typography/Text';
import { ContainedButton } from '@toyokumo/kintoneapp-ui';

type WhatIsKintoneAppAuthDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

function WhatIsKintoneAppAuthDialog({ isOpen, onClose }: WhatIsKintoneAppAuthDialogProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Toyokumo kintoneApp認証とは?"
      description="トヨクモ kintone連携サービスにログインするための認証システムです。GoogleかMicrosoftのアカウントがあれば直ぐにログインでき、パスワードを使わなくても安全な「メールアドレスでログイン」も選べます。"
    >
      <div>
        <Image
          src="/what-is-toyokumo-kintoneapp-auth.svg"
          alt="toyokumo kintoneapp auth"
          width={555}
          height={312}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <SubText>※認証付フォーム/ページの内容についてのご質問は、「回答/閲覧の依頼者」へお問い合わせください。</SubText>
      </div>
      <div className="mt-10">
        <Heading3>トヨクモ kintone連携サービス</Heading3>
        <Text>
          <BasicLink href="https://kintone.cybozu.co.jp/" target="_blank">
            サイボウズ キントーン
          </BasicLink>
          の機能を拡張するクラウドサービスです。キントーンのアカウントを持たない人でも使えるシステムを、ノーコードで簡単につくれます。
        </Text>
      </div>
      <hr className="mt-8" />
      <div className="mt-4 sm:flex sm:justify-between">
        <div>
          <Heading3>
            Toyokumo kintoneApp認証で、
            <br />
            業務改善をもっと手軽に。もっと簡単に。
          </Heading3>
        </div>
        <div className="mt-4 sm:mt-auto">
          <a href="https://kintoneapp.com/lp/kintoneapp-auth/" target="_blank" rel="noreferrer" className="w-full">
            <ContainedButton
              onClick={() => {
                /* do nothing */
              }}
              full
            >
              活用例はこちら
            </ContainedButton>
          </a>
        </div>
      </div>
    </Modal>
  );
}

export default WhatIsKintoneAppAuthDialog;

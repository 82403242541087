import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Heading1 from '@/components/typography/Heading1';
import Text from '@/components/typography/Text';
import { MdClose } from 'react-icons/md';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
  onTransitionEnd?: () => void;
  initialFocus?: React.RefObject<HTMLElement>;
};

// 共通して使用するモーダル
function Modal({ isOpen, onClose, title, description, action, children, onTransitionEnd, initialFocus }: Props) {
  return (
    <Transition show={isOpen} as="div" onTransitionEnd={onTransitionEnd}>
      <Dialog onClose={onClose} as="div" className="relative z-10" initialFocus={initialFocus}>
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[rgba(255,255,255,0.85)]" aria-hidden="true" />
        </Transition.Child>
        {/* Modal.Panelをdocumentの最初の位置から配置するためのコンテナ */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 overflow-y-auto">
            {/* Modal.Panelの外をクリックするかESCでonCloseが呼ばれる */}
            <Dialog.Panel className="mx-auto mt-8 max-w-[40rem] rounded bg-white p-8 shadow-[0_4px_16px_rgba(0,0,0,0.1)]">
              {/* Dialogのヘッダー */}
              <div className="flex items-center">
                <Dialog.Title as="div" className="grow">
                  <Heading1>{title}</Heading1>
                </Dialog.Title>
                {/* eslint-disable-next-line react/button-has-type */}
                <button onClick={onClose}>
                  <MdClose size={16} />
                </button>
              </div>
              {/* Dialogの説明文 */}
              {description && (
                <Dialog.Description as="div" className="mt-4">
                  <Text>{description}</Text>
                </Dialog.Description>
              )}
              {/* Dialogのコンテンツ */}
              <div className="mt-4">{children}</div>
              {/* ダイアログのボタン部分 */}
              {action && <div className="mt-8 flex justify-end">{action}</div>}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default Modal;

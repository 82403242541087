import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function PCText({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/pc-text.svg" alt="PrintCreator service name" />;
}

export default PCText;

import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function KVLogo({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/kv-logo.svg" alt="kViewer logo" />;
}

export default KVLogo;

import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function FBText({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/fb-text.svg" alt="FormBridge service name" />;
}

export default FBText;

import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function PCLogo({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/pc-logo.svg" alt="PrintCreator logo" />;
}

export default PCLogo;

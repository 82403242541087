import ToyokumoLogo from '@/components/ToyokumoLogo';
import ToyokumoKintoneAppText from '@/components/ToyokumoKintoneAppText';
import React from 'react';

type Props = {
  onClick?: (e: React.SyntheticEvent) => void;
};

// トヨクモロゴとサービス名が縦に並ぶComponent
function VerticalLogoAndServiceName({ onClick }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="flex cursor-pointer flex-col items-center space-y-5 leading-[0]" onClick={onClick}>
      <div>
        <ToyokumoLogo width={50} height={50} />
      </div>
      <div>
        <ToyokumoKintoneAppText width={216} height={23} />
      </div>
    </div>
  );
}

export default VerticalLogoAndServiceName;

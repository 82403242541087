import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function FBLogo({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/fb-logo.svg" alt="FormBridge logo" />;
}

export default FBLogo;

import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

function KMText({ width, height }: Props) {
  return <Image width={width} height={height} src="/app/km-text.svg" alt="kMailer service name" />;
}

export default KMText;
